import React from 'react'
import { useEffect, useReducer, useState } from 'react'
import { authConfig } from '../lib/Auth/index'
import {
    get_attributes,
    get_patients,
    getInviteFromClient,
    get_initial_patients,
    get_archived_patients,
    get_profile,
    update_practitioner,
    update_subscription_event,
    update_fullscript_token,

} from '../lib/Api/Practitioner/index'
import { get_notifications } from '../lib/Api/Notification'
import { convertPrice } from '../helpers/generalHelpers'
import { MAX_PATIENT_COUNT } from '../constants/plans'
import updatePractitioner from '../lib/Api/Practitioner/updatePractitioner'
import { useHistory } from "react-router-dom";

import { queryClient } from '../index'; // Asegúrate de tener acceso a queryClient


import reducer, {
    SET_PRAC_ID,
    SET_GET_ALL_DATA,
    SET_EN_BD,
    SET_FIRST_LOGIN,
    SET_IS_AUTH,
    SET_IS_FULL,
    SET_PATIENTS,
    SET_PATIENT_DATA,
    SET_PATIENT_LIST,
    SET_ARCHIVED,
    SET_LOCKED,
    SET_REMAINING_SEATS,
    SET_STRIPE_ID,
    SET_SUBS_PKGS,
    SET_SUBSCRIPTION,
    SET_UNRESPONDED_REQ_COUNT,
    SET_ONE_TO_ONE_COURSES,
    SET_COURSE_SASS,
    SET_MAILCHIMP,
    SET_INCOMING_INVITATION_FROM_CLIENT,
    SET_INCOMING_INVITATION_FROM_CLIENT_FILTERED,
    SET_SHOWN_TUTORIAL,
    SET_SHOWN_NOTIFICATION,
    SET_INTAKE_FORM_SUBSCRIPTION,
    SET_CLINIC_DATA,
    SET_CLINIC_IDS,
    SET_CLINIC_REQUESTS,
    SET_CLINIC_PATIENTS_ASSIGNED_PRACTITIONER
} from '../reducers/appData'

/** Return App initial state */
const initApp = () => {
    return {
        id: '',
        enBd: false,
        firstLogin: true,
        isAuth: authConfig.getUser() !== null,
        isFull: false,
        patients: [],
        patientData: {},
        patientList: [],
        archived: [],
        locked: {},
        remainingSeats: MAX_PATIENT_COUNT,
        stripeId: '',
        subsPkgs: [],
        subscription: {},
        unrespondedReqCount: 0,
        oneToOneCourses: [],
        courseSASS: null,
        mailchimp: {},
        invitations: [],
        invitationsNotified: [],
        shownTutorial: false,
        shownNotification: false,
        intakeformSubscription: {}
    }
}

/** Reducer accepts an action type and returns the current state, dispatch pair. */
const useAppData = () => {
    const history = useHistory();

    const [refresh, setRefresh] = useState(false)
    const [state, dispatch] = useReducer(reducer, initApp())
    const [hasIncomingInvitation, setHasIncomingInvitation] = useState(false);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    // Set methods for each state
    const setId = (id) => dispatch({ type: SET_PRAC_ID, value: id })
    const setNotificationView = (bol) => dispatch({ type: SET_SHOWN_NOTIFICATION, value: bol })
    const setAllData = (bol) => dispatch({ type: SET_GET_ALL_DATA, value: bol })
    const setEnBd = (enBd) => dispatch({ type: SET_EN_BD, value: enBd })
    const setFirstLogin = (firstLogin) =>
        dispatch({ type: SET_FIRST_LOGIN, value: firstLogin })
    const setIsAuth = () =>
        dispatch({ type: SET_IS_AUTH, value: authConfig.getUser() !== null })
    const setIsFull = (isFull) => dispatch({ type: SET_IS_FULL, value: isFull })
    const setRemainingSeats = (remainingSeats) =>
        dispatch({ type: SET_REMAINING_SEATS, value: remainingSeats })
    const setPatients = (patients) =>
        dispatch({ type: SET_PATIENTS, value: patients })
    const setCourseSASS = (SASS) =>
        dispatch({ type: SET_COURSE_SASS, value: SASS })
    const setMailchimp = (data) =>
        dispatch({ type: SET_MAILCHIMP, value: data })
    const setPatientData = (patient) =>
        dispatch({ type: SET_PATIENT_DATA, value: patient })
    const setPatientList = (patientList) =>
        dispatch({ type: SET_PATIENT_LIST, value: patientList })
    const setArchived = (archived) =>
        dispatch({ type: SET_ARCHIVED, value: archived })
    const setLocked = (locked) => dispatch({ type: SET_LOCKED, value: locked })
    const setStripeId = (stripeId) =>
        dispatch({ type: SET_STRIPE_ID, value: stripeId })
    const setSubsPkgs = (subsPkgs, delay = 1500) => {
        // Notification delay: Workaround for children unmounting due to parent state change issue
        // This delay allows the edit success notification to show for a sufficient amount of time
        // to notify user, then update the App's state, which causes the children component to unmount
        setTimeout(
            () => dispatch({ type: SET_SUBS_PKGS, value: subsPkgs }),
            delay
        )
    }
    const setSubscription = (subscription) =>
        dispatch({ type: SET_SUBSCRIPTION, value: subscription })
    const setUnrespondedReqCount = (reqCount) =>
        dispatch({ type: SET_UNRESPONDED_REQ_COUNT, value: reqCount })
    const decrementReqCount = () =>
        setUnrespondedReqCount(state.unrespondedReqCount - 1)
    const setOneToOneCourses = (courses) => dispatch({ type: SET_ONE_TO_ONE_COURSES, value: courses });

    const setInvitations = (invitations) => dispatch({ type: SET_INCOMING_INVITATION_FROM_CLIENT, value: invitations });
    const setInvitationsNotified = (invitations) => dispatch({ type: SET_INCOMING_INVITATION_FROM_CLIENT_FILTERED, value: invitations });
    const setShownTutorial = (bol) => dispatch({ type: SET_SHOWN_TUTORIAL, value: bol });
    const setIntakeformSubscription = (val) => dispatch({ type: SET_INTAKE_FORM_SUBSCRIPTION, value: val });
    const setClinicData = (val) => dispatch({ type: SET_CLINIC_DATA, value: val });
    const setClinicIds = (val) => dispatch({ type: SET_CLINIC_IDS, value: val });
    const setClinicRequests = (val) => dispatch({ type: SET_CLINIC_REQUESTS, value: val });
    const setClientsAssignedToPractitioners = (val) => dispatch({ type: SET_CLINIC_PATIENTS_ASSIGNED_PRACTITIONER, value: val });



    useEffect(() => {

        async function getNotificationViewed() {
            const notification = await get_notifications()
            console.log('notification', notification)
            if (notification.Viewed === false) {
                setNotificationView(true)
            }
        }
        if (state.isAuth) {
            getNotificationViewed()
        }


    }, [state.shownNotification])

    const renderPatients = () => {
        get_attributes(['patients'])
            .then((res) => {
                console.log('res', res)
                setPatients(res.patients)
            })
            .catch((err) =>
                console.log('renderPatients::get_attributes::error - ', err)
            )
    }

    const updatePatientStatus = (wasArchived, patIds) => {
        try {
            const newStatus = wasArchived ? 'active' : 'archived'
            const updateStatusPromises = patIds
                .filter((id) => id !== process.env.REACT_APP_SAMPLE_USER_ID)
                .map((id) =>
                    update_subscription_event(
                        { statusVal: newStatus },
                        `event#${id}`
                    )
                )
            return Promise.all(updateStatusPromises)
        } catch (err) {
            console.log('updatePatientStatus::err - ', err)
        }
    }

    const getSetPatients = async (isFull, patientIds, archivedIds, subInfo) => {
        try {
            if (isFull && subInfo?.plan_id) {
                const planId = subInfo.plan_id
                switch (planId) {
                    // Monthly metered subscription
                    case 'A-V1':
                        const newPatients = await get_patients(
                            patientIds,
                            archivedIds
                        )
                        setArchived(archivedIds)
                        setPatients({ ...state.patients, ...newPatients })
                        break
                    case 'A-V2':
                        // Yearly recurring subscription
                        const activePatients = subInfo.active_patients
                            ? subInfo.active_patients
                            : []
                        // Filter out active patients who are no longer in the patients list
                        const activePatientIds = activePatients.filter((id) =>
                            patientIds.includes(id)
                        )
                        // Filter for archived patients who are not in the active list
                        const archivedPatientIds = archivedIds.filter(
                            (id) => !activePatientIds.includes(id)
                        )
                        // Filter for locked patients who are neither in the active and archived list
                        const lockedPatientIds = patientIds.filter((id) => {
                            if (
                                !activePatients.includes(id) &&
                                !archivedIds.includes(id) &&
                                id !== process.env.REACT_APP_SAMPLE_USER_ID
                            )
                                return id
                        })
                        // if (!archivedIds.includes(process.env.REACT_APP_SAMPLE_USER_ID)) {
                        //     activePatientIds.unshift(process.env.REACT_APP_SAMPLE_USER_ID)
                        // }

                        const newActivePatients = await get_patients(
                            activePatientIds,
                            []
                        )
                        const newArchivedPatients = await get_patients(
                            archivedPatientIds,
                            archivedPatientIds
                        )
                        const newLockedPatients = await get_patients(
                            lockedPatientIds,
                            lockedPatientIds
                        )
                        const newActiveArchivedPatients = {
                            ...newActivePatients,
                            ...newArchivedPatients,
                            ...newLockedPatients,
                        }
                        setArchived(archivedPatientIds)
                        setLocked(newLockedPatients)
                        setPatients(newActiveArchivedPatients)
                        break
                    default:
                        break
                }
            } else {
                let newLockedIds = patientIds.filter(
                    (id) =>
                        !archivedIds.includes(id) &&
                        id !== process.env.REACT_APP_SAMPLE_USER_ID
                )
                let newPatientIds = newLockedIds.splice(0, 1)
                if (
                    !archivedIds.includes(process.env.REACT_APP_SAMPLE_USER_ID)
                ) {
                    newPatientIds.unshift(process.env.REACT_APP_SAMPLE_USER_ID)
                }
                let newArchivedPatients = await get_patients(
                    archivedIds,
                    archivedIds
                )
                let newLockedPatients = await get_patients(
                    newLockedIds,
                    newLockedIds
                )
                let newPatients = await get_patients(newPatientIds, archivedIds)
                newPatients = {
                    ...newPatients,
                    ...newLockedPatients,
                    ...newArchivedPatients,
                }
                setArchived(archivedIds)
                setLocked(newLockedPatients)
                setPatients(newPatients)
            }
        } catch (err) {
            console.log('app::get_patients::error - ', err)
        }
    }
    const getInitialPatientInfo = async (isFull, patientIds, archivedIds, subInfo) => {
        try {
            if (isFull && subInfo?.plan_id) {
                const planId = subInfo.plan_id
                switch (planId) {
                    // Monthly metered subscription
                    case 'A-V1':
                        console.log('A-V1')
                        console.log({
                            patientIds,
                            archivedIds
                        })
                        const newPatients = await queryClient.fetchQuery(
                            ['get_initial_patients', patientIds, archivedIds],
                            () => get_initial_patients(patientIds, archivedIds)
                        );
                        console.log('---- setPatients')
                        setArchived(archivedIds)
                        setPatients({ ...state.patients, ...newPatients })
                        break
                    case 'A-V2':
                        // Yearly recurring subscription
                        console.log('A-V2')
                        const activePatients = subInfo.active_patients
                            ? subInfo.active_patients
                            : []
                        // Filter out active patients who are no longer in the patients list
                        const activePatientIds = activePatients.filter((id) =>
                            patientIds.includes(id)
                        )
                        // Filter for archived patients who are not in the active list
                        const archivedPatientIds = archivedIds.filter(
                            (id) => !activePatientIds.includes(id)
                        )
                        // Filter for locked patients who are neither in the active and archived list
                        const lockedPatientIds = patientIds.filter((id) => {
                            if (
                                !activePatients.includes(id) &&
                                !archivedIds.includes(id) &&
                                id !== process.env.REACT_APP_SAMPLE_USER_ID
                            )
                                return id
                        })
                        // if (!archivedIds.includes(process.env.REACT_APP_SAMPLE_USER_ID)) {
                        //     activePatientIds.unshift(process.env.REACT_APP_SAMPLE_USER_ID)
                        // }
                        const newActivePatients = await queryClient.fetchQuery(
                            ['get_initial_patients', activePatientIds, []],
                            () => get_initial_patients(activePatientIds, [])
                        );

                        const newArchivedPatients = await queryClient.fetchQuery(
                            ['get_initial_patients', archivedPatientIds, archivedPatientIds],
                            () => get_initial_patients(archivedPatientIds, archivedPatientIds)
                        );

                        const newLockedPatients = await queryClient.fetchQuery(
                            ['get_initial_patients', lockedPatientIds, lockedPatientIds],
                            () => get_initial_patients(lockedPatientIds, lockedPatientIds)
                        );

                        const newActiveArchivedPatients = {
                            ...newActivePatients,
                            ...newArchivedPatients,
                            ...newLockedPatients,
                        }
                        setArchived(archivedPatientIds)
                        console.log('newLocked Patients', newLockedPatients)
                        setLocked(newLockedPatients)
                        setPatients(newActiveArchivedPatients)
                        break
                    default:
                        break
                }
            } else {
                let newLockedIds = patientIds.filter(
                    (id) =>
                        !archivedIds.includes(id) &&
                        id !== process.env.REACT_APP_SAMPLE_USER_ID
                )
                console.log('locked', newLockedIds)
                let newPatientIds = newLockedIds.splice(0, 1)
                if (
                    !archivedIds.includes(process.env.REACT_APP_SAMPLE_USER_ID)
                ) {
                    newPatientIds.unshift(process.env.REACT_APP_SAMPLE_USER_ID)
                }
                let newArchivedPatients = await queryClient.fetchQuery(
                    ['get_initial_patients', archivedIds, archivedIds],
                    () => get_initial_patients(archivedIds, archivedIds)
                );

                let newLockedPatients = await queryClient.fetchQuery(
                    ['get_initial_patients', newLockedIds, newLockedIds],
                    () => get_initial_patients(newLockedIds, newLockedIds)
                );

                let newPatients = await queryClient.fetchQuery(
                    ['get_initial_patients', newPatientIds, archivedIds],
                    () => get_initial_patients(newPatientIds, archivedIds)
                );


                newPatients = {
                    ...newPatients,
                    ...newLockedPatients,
                    ...newArchivedPatients,
                }
                setArchived(archivedIds)
                setLocked(newLockedPatients)
                setPatients(newPatients)
            }
        } catch (err) {
            console.log('app::get_patients::error - ', err)
        }
    }

    const renderArchivedPatients = async (data, patientWasArchived, ids) => {
        await update_practitioner('archived', data)
        console.log(state)

        if (
            (state.plan_id &&
                state.plan_od !== 'A-V2' &&
                state.statusVal === 'paid') ||
            state.statusVal === 'draft'
        ) {
            await updatePatientStatus(patientWasArchived, ids)
        }



        window.location.reload()
    }

    const getArchivedPatients = async () => {
        console.log('existed', { ...state.patients })
        const archivedPatients = await get_archived_patients(state.archived)
        console.log('archivedPatients', archivedPatients)
        setPatients({ ...state.patients, archivedPatients })
    }

    const renderSubsPkgs = () => {
        get_attributes(['subscription_packages'])
            .then((res) => setSubsPkgs(res.subscription_packages))
            .catch((err) =>
                console.log('renderSubsPkgs::get_attributes::error - ', err)
            )
    }





    useEffect(() => {
        if (state.isAuth) {
            console.log('reaches here')
            // setFirstLogin(localStorage.getItem('isFirstLogin') === 'true')
            setFirstLogin(false)
            get_profile()
                .then(async (practitioner) => {
                    console.log('app::get_profile::success - ', practitioner)
                    if (practitioner?.clinicProfile) {
                        setClinicData({
                            clinicPractitioner: practitioner?.clinicPractitioner,
                            clinicPractitionerRequests: practitioner?.clinicPractitionerRequests
                        })
                    }

                    if (practitioner?.clinicIds && practitioner?.clinicIds?.length > 0) {
                        setClinicIds(practitioner.clinicIds)
                    }

                    if (practitioner?.clinicRequests && practitioner?.clinicRequests?.length > 0) {
                        setClinicRequests(practitioner.clinicRequests)
                    }
                    if (practitioner?.clientsAssignedToPractitioners) {
                        setClientsAssignedToPractitioners(practitioner.clientsAssignedToPractitioners)
                    }

                    setId(practitioner.id)
                    setName(practitioner.firstName)
                    setEmail(practitioner.email)
                    if (practitioner.isFirstLogin) {
                        setShownTutorial(true)
                    }
                    setIntakeformSubscription(practitioner.intakeformSubscription)
                    // const notification = await get_notifications()
                    // console.log('notification', notification)
                    // setNotificationView(notification.Viewed)

                    let isFull = false

                    // incoming invitations from patient:

                    const invitations = await getInviteFromClient(practitioner.email);
                    const unNotified = invitations.filter((item) => item.is_notified_to_coach === false)

                    setInvitations(invitations)
                    setInvitationsNotified(unNotified)

                    if (unNotified.length > 0) {
                        setHasIncomingInvitation(true)
                    }

                    const purchaseHist = practitioner.purchaseHistory
                    const hasPurchaseHist =
                        purchaseHist && purchaseHist.length > 0
                    const subscription = purchaseHist[purchaseHist.length - 1]
                    const isYearlyPlan = subscription?.plan_id === 'A-V2'

                    if (hasPurchaseHist) {
                        if (
                            (subscription.subscription_end >= Date.now() &&
                                subscription.statusVal === 'paid') ||
                            subscription.statusVal === 'draft'
                        ) {
                            isFull = true
                            if (isYearlyPlan && subscription.patient_count) {
                                const activePatients =
                                    subscription?.active_patients
                                const patientSeats = activePatients
                                    ? activePatients.length
                                    : 0
                                const patientCount = subscription?.patient_count
                                setRemainingSeats(patientCount - patientSeats)
                            }
                        }
                        setSubscription(subscription)
                    }
                    if (!isFull)
                        setRemainingSeats(
                            MAX_PATIENT_COUNT -
                            (practitioner.patients.length - 1)
                        )
                    setCourseSASS(practitioner.coursesSASS ? practitioner.coursesSASS : null)
                    setMailchimp(practitioner.mailchimp && Object.keys(practitioner.mailchimp).length > 0 ? practitioner.mailchimp : null)
                    setEnBd(hasPurchaseHist)
                    setIsFull(isFull)
                    setStripeId(practitioner.stripeId)
                    setUnrespondedReqCount(practitioner.unrespondedReqCount)
                    setSubsPkgs(
                        convertPrice(practitioner.subscriptionPackages),
                        0
                    )
                    setPatientList(practitioner.patients)
                    setOneToOneCourses(practitioner.oneToOneCourses)

                    try {
                        console.log(
                            isFull,
                            practitioner.patients,
                            practitioner.archived,
                            subscription)
                        getInitialPatientInfo(
                            isFull,
                            practitioner.patients,
                            practitioner.archived,
                            subscription
                        )

                        if (practitioner.fullscriptActive) {
                            updateFullScriptTokenCall(practitioner.id, practitioner.fullscriptCountry)
                        } else {
                            localStorage.removeItem('fsAccessToken')
                            localStorage.removeItem('fsRefreshToken')
                            localStorage.removeItem('fsAccessTokenExpiredAt')
                        }

                    } catch (err) {
                        console.log('app::get_patients::error - ', err)
                    }
                })
                .catch((err) => {
                    console.log('app::get_profile::error - ', err)
                    window.location.href = '/signin';


                })
        }
    }, [state.isAuth, refresh])







    const updateFullScriptTokenCall = async (id, fullscriptCountry) => {

        try {
            const token = await update_fullscript_token(id, fullscriptCountry)

            let createdAt = new Date(token.created_at)
            const expiredAt = createdAt.setSeconds(
                createdAt.getSeconds() + token.expires_in
            )

            const newAttributes = {
                fs_access_token: token.access_token,
                fs_refresh_token: token.refresh_token,
                fs_access_token_expired_at: expiredAt,
                fs_location: fullscriptCountry,
            }

            Promise.all(
                Object.keys(newAttributes).map((attr) =>
                    updatePractitioner(attr, newAttributes[attr])
                )
            )
                .then((data) => {
                    localStorage.setItem(
                        'fsAccessToken',
                        token.access_token
                    )
                    localStorage.setItem(
                        'fsRefreshToken',
                        token.refresh_token
                    )
                    localStorage.setItem(
                        'fsAccessTokenExpiredAt',
                        expiredAt
                    )
                })





        } catch (err) {

        }


    }

    const getMorePatients = async () => {
        // Define cuántos pacientes cargar por iteración
        const batchSize = 10;

        // IDs que ya están cargados
        const loadedPatientIds = Object.keys(state.patients);
        console.log('loadedPatientIds:', loadedPatientIds);

        // IDs que están archivados
        const archivedPatientIds = state.archived || [];
        console.log('archivedPatientIds:', archivedPatientIds);

        // Todos los IDs disponibles para cargar
        const availablePatientIds = state.patientList;
        console.log('availablePatientIds:', availablePatientIds);

        // Filtrar los que aún no han sido cargados ni están archivados
        const remainingPatients = availablePatientIds.filter(
            id => !loadedPatientIds.includes(id) && !archivedPatientIds.includes(id)
        );
        console.log('remainingPatients:', remainingPatients);

        if (remainingPatients.length === 0) {
            console.log("No more patients to load.");
            return 101;
        }

        // Obtener el siguiente lote de IDs de pacientes
        const nextBatch = remainingPatients.slice(0, batchSize);

        console.log('Next batch to load:', nextBatch);

        const newPatients = await queryClient.fetchQuery(
            ['get_initial_patients', nextBatch, state.archived],
            () => get_initial_patients(nextBatch, state.archived)
        );
    
        setPatients({ ...state.patients, ...newPatients })

        return 100

    };


    return {
        hasIncomingInvitation,
        setHasIncomingInvitation,
        state,
        name,
        email,
        setNotificationView,
        setAllData,
        setFirstLogin,
        setIsAuth,
        setPatients,
        setStripeId,
        setSubsPkgs,
        setCourseSASS,
        setUnrespondedReqCount,
        setShownTutorial,
        decrementReqCount,
        renderPatients,
        renderArchivedPatients,
        renderSubsPkgs,
        getArchivedPatients,
        getMorePatients
    }
}

export default useAppData
