import { getUser } from './config'

const SIX_HOURS_IN_MILLISECONDS = 6 * 60 * 60 * 1000;

const updateLastRequestDate = () => {
    const lastRequest = localStorage.getItem('lastRequestDate');
    const now = new Date();

    if (!lastRequest) {
        // No previous date, set current date
        localStorage.setItem('lastRequestDate', now.toISOString());
        return true; // Session is valid
    } else {
        const lastRequestDate = new Date(lastRequest);
        const timeDifference = now - lastRequestDate;

        if (timeDifference > SIX_HOURS_IN_MILLISECONDS) {
            // More than 6 hours of inactivity
            console.log("over 6 hours")
            localStorage.removeItem('lastRequestDate');
            return false; // Session expired
        } else {
            // Update the last request date
            console.log("less 6 hours")
            localStorage.setItem('lastRequestDate', now.toISOString());
            return true; // Session is valid
        }
    }
};


const getSession = () => {
    const cognitoUser = getUser()
    const isSessionValid = updateLastRequestDate();

    if (!isSessionValid) {
        cognitoUser.signOut()
        window.location.href = '/signin';
        throw new Error('Session expired due to inactivity');
    }

    if (cognitoUser && isSessionValid) {
        return new Promise((resolve, reject) => {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err.message || JSON.stringify(err))
                } else {
                    // console.log('Session: ', session);
                    const id = session.accessToken.payload.sub
                    resolve({
                        id: id,
                        accessToken: session.accessToken.jwtToken,
                        email: session.idToken.payload.email,
                    })
                }
            })
        })
    } else {
        throw new Error('no cognitiveUser value')
    }
}

export default getSession
