import axios from 'axios';
import { getAuthSession } from '../../Auth/index';
import { baseUrl } from '../config';
import { decrypt, getAge, newDecrypt } from '../../Patient/index';

/**
 * Function to fetch patient-related data.
 * 
 * @param {String[]} patientsId - An array of patients' Firebase ids.
 * @param {String[]} archivedIds - Archived patient ids.
 * @return {Promise<Object>}
 */
const get_initial_patients = async (patientsId, archivedIds) => {
    const { accessToken } = await getAuthSession();
    const reqBody = {
        headers: { Authorization: `Bearer ${accessToken}` },
    };

    let patientIdsNoArchived = patientsId.filter(
        (id) => !archivedIds.includes(id)
    );

     // Verificar si hay más de 10 elementos en patientIds
     if (patientIdsNoArchived.length > 10) {
        console.log('Recortando patientIds a los primeros 10 elementos');
        patientIdsNoArchived = patientIdsNoArchived.slice(0, 10); // Mantener solo los primeros 10 elementos
        console.log(patientIdsNoArchived);
    }

    const params = { patientsId: patientIdsNoArchived };



    const response = await axios.post(
        `${baseUrl}/patients/getPatients`,
        params,
        reqBody
    );

    const validPatients = response.data.filter(
        (patient) => patient !== null && Object.values(patient)[0] !== null
    );

    let users = {};
    validPatients.forEach((patient, i) => {
        const id = Object.keys(patient)[0];

        if (archivedIds.includes(id)) {
            const data = Object.values(patient)[0];
            const basicInfo = data?.basicInfo || {};
            const subsInfo = data?.subsInfo || {};
            users[id] = { basicInfo, subsInfo, status: 'archived' };
        } else {
            users[id] = Object.values(patient)[0];
        }

        users[id].id = id;
        users[id].index = i;

        let firstName;
        if (users?.[id]?.basicInfo?.firstName) {
            firstName = decrypt(users?.[id]?.basicInfo?.firstName);
        } else if (users?.[id]?.basicInfo?.name) {
            firstName = newDecrypt(users?.[id]?.basicInfo?.name);
        } else {
            firstName = 'None';
        }

        let age;
        if (users?.[id]?.basicInfo?.birthDate) {
            age = decrypt(users?.[id]?.basicInfo?.birthDate);
        } else if (users?.[id]?.basicInfo?.birth) {
            age = newDecrypt(users?.[id]?.basicInfo?.birth);
        } else {
            age = 'Not available';
        }

        if (age !== 'Not available') age = getAge(age);

        if (!users[id].basicInfo) users[id].basicInfo = {};

        users[id].basicInfo.firstName =
            firstName.charAt(0).toUpperCase() + firstName.slice(1);
        users[id].basicInfo.age = age;
    });

    return users;
};

// /**
//  * React Query hook to fetch and cache patient-related data.
//  * 
//  * @param {String[]} patientsId - An array of patients' Firebase ids.
//  * @param {String[]} archivedIds - Archived patient ids.
//  */
// const useFetchInitialPatients = (patientsId, archivedIds) => {
//     return useQuery(
//         ['get_initial_patients', patientsId, archivedIds], // Unique cache key
//         () => fetchInitialPatients(patientsId, archivedIds), // Fetch function
//         {
//             staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
//             cacheTime: 10 * 60 * 1000, // Keep data in cache for 10 minutes
//             enabled: !!patientsId?.length, // Only fetch if there are patient IDs
//         }
//     );
// };

export { get_initial_patients };
