import axios from 'axios'
import { populateIndex } from '../../../helpers/generalHelpers'
import { getAuthSession } from '../../Auth/index'
import { baseUrl } from '../config'

/**
 * Get a list of program items from program patient table in dynamoDB.
 *
 * @return {Promise<any>}
 */
const getPatientsForSearchBar = (patientIds) => {
    return new Promise((resolve, reject) => {
        getAuthSession()
            .then(({ accessToken }) => {
                const reqBody = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
                const params = { patientIds }
                axios
                    .post(`${baseUrl}/patients/getPatientsForSearchBar`, params,
                        reqBody)
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default getPatientsForSearchBar
