import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { QueryClient, QueryClientProvider } from 'react-query'
import App from './App'
import * as serviceWorker from './elements/serviceWorker'
import './styles/index.css'
import FlokaTheme from './styles/Theme'

// Crear un nuevo cliente de consultas
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3, // Configuración opcional: reintentar en caso de error
      refetchOnWindowFocus: false, // No volver a buscar al cambiar el foco.
      staleTime: 1000 * 60 * 60 * 7, // Los datos son frescos durante 7 horas
      cacheTime: 1000 * 60 * 60 * 7, // Los datos permanecen en caché 7 horas
    },
  },
});

ReactDOM.render(
    <MuiThemeProvider theme={FlokaTheme}>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </MuiThemeProvider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
